import { count } from "rxjs/operators";
import {faHome, faFileCsv, faFileExcel, faFileWord, faPhotoVideo, faFileCode, faFileImage, faMobileAlt, faFileVideo, faFileAudio, faFilePdf, faFilePowerpoint, faFileArchive, faFileAlt, faQuestionCircle, IconDefinition, faDatabase, faCropAlt, faPlusSquare, faUserPlus, faSearch, faPlusCircle, faSmile, faFrown, faTimesCircle } from '@fortawesome/free-solid-svg-icons';


export class AppConsts {
    static remoteServiceBaseUrl: string;
    static appBaseUrl: string;
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'ComplianceModule'
    };

    static readonly authorization = {
        encryptedAuthTokenName: 'enc_auth_token'
    };

    static getHostServe = function() {
        /*if(location.href.indexOf("localhost") != -1)
            return "http://localhost:21021";            
        else*/            
            return "http://52.6.240.66:8200";
    }
    
    static getAppBaseUrl = function() {
        if(location.href.indexOf("localhost") != -1)
            return "http://localhost:4200";
        else
            return this.appBaseUrl;
    }

    static setModalWidth(width: string, heigth: string) {
        let widthPx = $("body").width() * this._porcent(width);
        let heigthPx = 0;
        if (heigth != null || heigth != undefined)
             heigthPx = $("body").height() * this._porcent(heigth);
        var style = {
            'width.px': widthPx,
            'height.px': heigthPx,
            'background': '#222'
        };
        return style;
    }

    static setStyleBackground(element: string, color: string): void {
        $(element).css("background", color);
    }
    
    static dialogInputMsg():string{
        var html = '<mat-form-field class="col-md-12">'
                    +'<textarea matInput '
                                +'id="input"'
                                +'maxlength="500" style="width:100%; height:150px"></textarea> '
                    +'</mat-form-field>'+
                    '<p>Do you want to save?</p>';
        return html;
    }

    static optionStyleClear():void{
        $(".swal-icon").removeClass("swal-icon--warning");
        $(".swal-icon").removeClass("swal-icon");
        $(".swal-icon--warning__body").removeClass("swal-icon--warning__body");
    }

    private static _porcent(value: string): number {
        var number = 0;
        if (value.indexOf("%") == -1) {
            number = parseInt(value.split("%")[0]);
        }
        else
            number = parseInt(value);

        return number;
    }
}


export class EnumIcons{
 public static QUESTION = faQuestionCircle;
 public static GetIcon(){
    return [
        {CSV : faFileCsv},
        {DOC : faFileWord},
        {DOCX : faFileWord},
        {ODT : faFileWord},
        {XLSX : faFileExcel},
        {ODS : faFileExcel},
        {GIF : faFileImage},
        {HTML : faFileCode},
        {JPEG : faFileImage},
        {JPG : faFileImage},
      //  {3GP :   faMobileAlt},
        {MPG : faFileVideo},
        {MP3 : faFileAudio},
        {MP4 : faFileVideo},
        {PDF : faFilePdf},
        {PNG : faFileImage},
        {ODP : faFilePowerpoint},
        {PPT : faFilePowerpoint},
        {PPTX : faFilePowerpoint},
        {ZIP : faFileArchive},
        {SQL : faDatabase},
        {SVG : faCropAlt},
        {PLUS : faPlusSquare},
        {USERPLUS : faUserPlus},
        {SEARCH: faSearch},
        {TEST: faPlusCircle},
        {HAPPY: faSmile},
        { SAD: faFrown },
        { TimeCicle: faTimesCircle}
    ]
 }

 public static GetIconByName(name : string){
    var object = null;
    var icons = this.GetIcon();
    icons.forEach(item =>{
        var nameItem = Object.getOwnPropertyNames(item).toString();
        if(nameItem == name)
            object = item[name];
    });
    return object;
 }
}

// ************************** Seção dos enumerables ************************************
export enum EnumExt{
    CSV = ".csv",
    DOC = ".doc",
    DOCX = ".docx",
    EXCEL = ".xlsx",
    EXCEL_LIBRE = ".ods",
    GIF = ".gif",
    HTML = ".html",
    JPEG = ".jpeg",
    JPG = ".jpg",
    MOBILE = ".3gp",
    MPG = ".mpg",
    MP3 = ".mp3",
    MP4 = ".mp4",
    PDF = ".pdf",
    PNG = ".png",
    POWERPOINT_libre = ".odb",
    PPT = ".ppt",
    PPTX = ".pptx",
    RAR = ".rar",
    SQL = ".sql",
    SVG = ".svg",
    TAR = ".tar",
    TXT = ".txt",
    WAV = ".wav",
    WMV = ".wmv",
    WORD_LIBRE = ".odt",
    XML = ".xml",
    ZIP = ".zip"
}

export enum TypeCountry {
    UK,
    CANADA,
    BAHAMAS,
    INGLATERRA,
    IRLANDA,
    US,
    ESCOCIA,
    GANA,
    BR,
    INDIA,
    FILIPINAS,
    ISRAEL,
    CINGAPURA
}

export enum Expired {
    RG__Brasilian_ID,
    Passport,
    Foreign_ID,
    Legal_State_ID,
    Driver_License,
    Bank_Card
}
