import { AppComponentBase } from 'shared/app-component-base';
import { Injector, OnInit } from '@angular/core';

export class PagedResultDto {
    items: any[];
    totalItems: number;
}

export class EntityDto {
    id: number;
}

export class PagedRequestDto {
    static skipCount: number;
    static maxResultCount: number;
    static keyword: string;
    static isActive: Boolean;
    static HeadOffice: string;
}

export abstract class PagedListingComponentBase<TEntityDto> extends AppComponentBase implements OnInit {

    public pageSize = 7;
    public pageNumber = 1;
    public totalPages = 1;
    public totalItems: number;
    public isTableLoading = false;

    

    constructor(injector: Injector) {
        super(injector);
    }

    
    ngOnInit(): void {
        this.refresh();
    }

    public getPagesCount(){
        var list = [];
        for(var i = 1; i <= this.totalPages; i++){
            list.push(i);
        }
        return list;
    }

    refresh(): void {
        this.getDataPage(this.pageNumber);
    }

    public showPaging(result: PagedResultDto, pageNumber: number): void {
        
        this.totalPages = ((result.totalItems - (result.totalItems % this.pageSize)) / this.pageSize) + 1;
        this.totalItems = result.totalItems;
        this.pageNumber = pageNumber;
        console.clear();
        console.log("Total: "+this.totalItems+" pagina: "+ this.pageNumber);
    }

    public getDataPage(page: number): void {
        PagedRequestDto.maxResultCount = this.pageSize;
        PagedRequestDto.skipCount = (page - 1); //* this.pageSize;



        this.isTableLoading = true;
        this.list(PagedRequestDto, page, () => {
            this.isTableLoading = false;
        });
    }

    protected abstract list(request: PagedRequestDto, pageNumber: number, finishedCallback: Function): void;
    protected abstract delete(entity: TEntityDto): void;
}
