import { PagedRequestDto } from ".././paged-listing-component-base";

export class RequestFilter {
    static getFilter(): string {
        let url_ = "";
        if (PagedRequestDto.isActive != undefined) {
            if (this._validarQueryString(PagedRequestDto.isActive))
                url_ += `isActive=${PagedRequestDto.isActive}`;
            if (this._validarQueryString(PagedRequestDto.skipCount))
                url_ += `&skipCount=${PagedRequestDto.skipCount}`;
            if (this._validarQueryString(PagedRequestDto.maxResultCount))
                url_ += `&maxResultCount=${PagedRequestDto.maxResultCount}`;
            if (this._validarQueryString(PagedRequestDto.keyword))
                url_ += `&keyword=${PagedRequestDto.keyword}`;
            if (this._validarQueryString(PagedRequestDto.HeadOffice))
                url_ += `&HeadOffice=${PagedRequestDto.HeadOffice}`;
        }
        else
            url_ += `isActive=true&skipCount=${PagedRequestDto.skipCount}&maxResultCount=${PagedRequestDto.maxResultCount}&keyword=${PagedRequestDto.keyword}`;
        return url_;
    }

    static _validarQueryString(key: any): boolean {
        if (key != null && key != undefined && key != "")
            return true;
        else
            return false;
    }   
}

